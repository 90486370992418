/**
 * Generally we want to extend default Error class to throw - to pass extra parameters and format it better
 * However, overriding Error.toString() doesn't work for some reason, probably because of Babel
 *
 * Still we can at least check if error is instanceof AxiosError to see if the error comes from Axios
 */

export default class AxiosError extends Error {
    name = 'AxiosError'
    status = null
    message = null
    errors = null
    response = {}

    constructor(message, status= 700, errors= null, original_error = null) {
        super(message, {cause: original_error});
        this.status = status
        this.message = message
        this.errors = errors
        this.response = original_error?.response
    }
}
