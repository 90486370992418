import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0417bc8b = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _130d52f0 = () => interopDefault(import('../pages/future-flipper.vue' /* webpackChunkName: "pages/future-flipper" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _40c9f919 = () => interopDefault(import('../pages/messenger.vue' /* webpackChunkName: "pages/messenger" */))
const _1b596bfe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _974947a2 = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _7f799735 = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _1086c684 = () => interopDefault(import('../pages/user/change-password.vue' /* webpackChunkName: "pages/user/change-password" */))
const _038b71de = () => interopDefault(import('../pages/user/documents.vue' /* webpackChunkName: "pages/user/documents" */))
const _70c2a1fd = () => interopDefault(import('../pages/user/favorites.vue' /* webpackChunkName: "pages/user/favorites" */))
const _12070b2a = () => interopDefault(import('../pages/user/notification-settings.vue' /* webpackChunkName: "pages/user/notification-settings" */))
const _700732e1 = () => interopDefault(import('../pages/user/offers.vue' /* webpackChunkName: "pages/user/offers" */))
const _9d5c4248 = () => interopDefault(import('../pages/user/open-houses.vue' /* webpackChunkName: "pages/user/open-houses" */))
const _525a93cf = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _d079cab0 = () => interopDefault(import('../pages/wholesaling-live.vue' /* webpackChunkName: "pages/wholesaling-live" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _02831c75 = () => interopDefault(import('../pages/reset-password/_auth_token.vue' /* webpackChunkName: "pages/reset-password/_auth_token" */))
const _5bbb54ee = () => interopDefault(import('../pages/unsubscribe/_auth_token.vue' /* webpackChunkName: "pages/unsubscribe/_auth_token" */))
const _438a5d1c = () => interopDefault(import('../pages/wholesaler-review/_offer_id/thank-you.vue' /* webpackChunkName: "pages/wholesaler-review/_offer_id/thank-you" */))
const _ba5e1da2 = () => interopDefault(import('../pages/property/_property_id/_property_slug.vue' /* webpackChunkName: "pages/property/_property_id/_property_slug" */))
const _f1f49d54 = () => interopDefault(import('../pages/property/_property_id/_property_slug/address-request.vue' /* webpackChunkName: "pages/property/_property_id/_property_slug/address-request" */))
const _b52e94c0 = () => interopDefault(import('../pages/property/_property_id/_property_slug/contact-manager.vue' /* webpackChunkName: "pages/property/_property_id/_property_slug/contact-manager" */))
const _7e030d00 = () => interopDefault(import('../pages/property/_property_id/_property_slug/get-financing.vue' /* webpackChunkName: "pages/property/_property_id/_property_slug/get-financing" */))
const _2ac814e7 = () => interopDefault(import('../pages/property/_property_id/_property_slug/inquiry.vue' /* webpackChunkName: "pages/property/_property_id/_property_slug/inquiry" */))
const _397224cd = () => interopDefault(import('../pages/property/_property_id/_property_slug/make-offer.vue' /* webpackChunkName: "pages/property/_property_id/_property_slug/make-offer" */))
const _421b8974 = () => interopDefault(import('../pages/property/_property_id/_property_slug/complete-offer/_offer_id.vue' /* webpackChunkName: "pages/property/_property_id/_property_slug/complete-offer/_offer_id" */))
const _fac075fc = () => interopDefault(import('../pages/property/_property_id/_property_slug/open-house/_open_house_id.vue' /* webpackChunkName: "pages/property/_property_id/_property_slug/open-house/_open_house_id" */))
const _447c8212 = () => interopDefault(import('../pages/wholesaler-review/_offer_id/_auth_token.vue' /* webpackChunkName: "pages/wholesaler-review/_offer_id/_auth_token" */))
const _8b0fccaa = () => interopDefault(import('../components/auth/auth-token-page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/forgot-password",
    component: _0417bc8b,
    name: "forgot-password"
  }, {
    path: "/future-flipper",
    component: _130d52f0,
    name: "future-flipper"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/messenger",
    component: _40c9f919,
    name: "messenger"
  }, {
    path: "/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register"
  }, {
    path: "/terms-of-service",
    component: _974947a2,
    name: "terms-of-service"
  }, {
    path: "/user",
    component: _7f799735,
    name: "user",
    children: [{
      path: "change-password",
      component: _1086c684,
      name: "user-change-password"
    }, {
      path: "documents",
      component: _038b71de,
      name: "user-documents"
    }, {
      path: "favorites",
      component: _70c2a1fd,
      name: "user-favorites"
    }, {
      path: "notification-settings",
      component: _12070b2a,
      name: "user-notification-settings"
    }, {
      path: "offers",
      component: _700732e1,
      name: "user-offers"
    }, {
      path: "open-houses",
      component: _9d5c4248,
      name: "user-open-houses"
    }, {
      path: "profile",
      component: _525a93cf,
      name: "user-profile"
    }]
  }, {
    path: "/wholesaling-live",
    component: _d079cab0,
    name: "wholesaling-live"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "direct-index"
  }, {
    path: "/properties/:state",
    component: _2dfb1658,
    props: true,
    name: "properties-state"
  }, {
    path: "/reset-password/:auth_token?",
    component: _02831c75,
    name: "reset-password-auth_token"
  }, {
    path: "/unsubscribe/:auth_token?",
    component: _5bbb54ee,
    alias: "/user/unsubscribe/:auth_token?",
    name: "unsubscribe-auth_token"
  }, {
    path: "/company/:account_slug/",
    component: _2dfb1658,
    name: "company-account_slug-index"
  }, {
    path: "/company/:account_slug/forgot-password",
    component: _0417bc8b,
    name: "company-account_slug-forgot-password"
  }, {
    path: "/company/:account_slug/future-flipper",
    component: _130d52f0,
    name: "company-account_slug-future-flipper"
  }, {
    path: "/company/:account_slug/login",
    component: _10ba8d22,
    name: "company-account_slug-login"
  }, {
    path: "/company/:account_slug/messenger",
    component: _40c9f919,
    name: "company-account_slug-messenger"
  }, {
    path: "/company/:account_slug/privacy-policy",
    component: _1b596bfe,
    name: "company-account_slug-privacy-policy"
  }, {
    path: "/company/:account_slug/register",
    component: _5fba350d,
    name: "company-account_slug-register"
  }, {
    path: "/company/:account_slug/terms-of-service",
    component: _974947a2,
    name: "company-account_slug-terms-of-service"
  }, {
    path: "/company/:account_slug/user",
    component: _7f799735,
    name: "company-account_slug-user",
    children: [{
      path: "change-password",
      component: _1086c684,
      name: "company-account_slug-user-change-password"
    }, {
      path: "documents",
      component: _038b71de,
      name: "company-account_slug-user-documents"
    }, {
      path: "favorites",
      component: _70c2a1fd,
      name: "company-account_slug-user-favorites"
    }, {
      path: "notification-settings",
      component: _12070b2a,
      name: "company-account_slug-user-notification-settings"
    }, {
      path: "offers",
      component: _700732e1,
      name: "company-account_slug-user-offers"
    }, {
      path: "open-houses",
      component: _9d5c4248,
      name: "company-account_slug-user-open-houses"
    }, {
      path: "profile",
      component: _525a93cf,
      name: "company-account_slug-user-profile"
    }]
  }, {
    path: "/company/:account_slug/wholesaling-live",
    component: _d079cab0,
    name: "company-account_slug-wholesaling-live"
  }, {
    path: "/wholesaler-review/:offer_id?/thank-you",
    component: _438a5d1c,
    name: "wholesaler-review-offer_id-thank-you"
  }, {
    path: "/company/:account_slug/properties/:state",
    component: _2dfb1658,
    props: true,
    name: "company-account_slug-properties-state"
  }, {
    path: "/company/:account_slug/reset-password/:auth_token?",
    component: _02831c75,
    name: "company-account_slug-reset-password-auth_token"
  }, {
    path: "/company/:account_slug/unsubscribe/:auth_token?",
    component: _5bbb54ee,
    name: "company-account_slug-unsubscribe-auth_token"
  }, {
    path: "/company/:account_slug/wholesaler-review/:offer_id?/thank-you",
    component: _438a5d1c,
    name: "company-account_slug-wholesaler-review-offer_id-thank-you"
  }, {
    path: "/company/:account_slug/property/:property_id?/:property_slug?",
    component: _ba5e1da2,
    name: "company-account_slug-property-property_id-property_slug",
    children: [{
      path: "address-request",
      component: _f1f49d54,
      name: "company-account_slug-property-property_id-property_slug-address-request"
    }, {
      path: "contact-manager",
      component: _b52e94c0,
      name: "company-account_slug-property-property_id-property_slug-contact-manager"
    }, {
      path: "get-financing",
      component: _7e030d00,
      name: "company-account_slug-property-property_id-property_slug-get-financing"
    }, {
      path: "inquiry",
      component: _2ac814e7,
      name: "company-account_slug-property-property_id-property_slug-inquiry"
    }, {
      path: "make-offer",
      component: _397224cd,
      name: "company-account_slug-property-property_id-property_slug-make-offer"
    }, {
      path: "complete-offer/:offer_id?",
      component: _421b8974,
      name: "company-account_slug-property-property_id-property_slug-complete-offer-offer_id"
    }, {
      path: "open-house/:open_house_id?",
      component: _fac075fc,
      name: "company-account_slug-property-property_id-property_slug-open-house-open_house_id"
    }]
  }, {
    path: "/company/:account_slug/wholesaler-review/:offer_id?/:auth_token?",
    component: _447c8212,
    name: "company-account_slug-wholesaler-review-offer_id-auth_token"
  }, {
    path: "/property/:property_id?/:property_slug?",
    component: _ba5e1da2,
    alias: "/p/:property_id",
    name: "property-property_id-property_slug",
    children: [{
      path: "address-request",
      component: _f1f49d54,
      name: "property-property_id-property_slug-address-request"
    }, {
      path: "contact-manager",
      component: _b52e94c0,
      name: "property-property_id-property_slug-contact-manager"
    }, {
      path: "get-financing",
      component: _7e030d00,
      name: "property-property_id-property_slug-get-financing"
    }, {
      path: "inquiry",
      component: _2ac814e7,
      name: "property-property_id-property_slug-inquiry"
    }, {
      path: "complete-offer/:offer_id?",
      component: _421b8974,
      name: "property-property_id-property_slug-complete-offer-offer_id"
    }, {
      path: "open-house/:open_house_id?",
      component: _fac075fc,
      name: "property-property_id-property_slug-open-house-open_house_id"
    }]
  }, {
    path: "/wholesaler-review/:offer_id?/:auth_token?",
    component: _447c8212,
    name: "wholesaler-review-offer_id-auth_token"
  }, {
    path: "/property/:property_id/:property_slug/make-offer",
    component: _397224cd,
    props: true,
    name: "property-property_id-property_slug-make-offer"
  }, {
    path: "*/auth-token/:auth_token",
    component: _8b0fccaa,
    props: true,
    alias: "*/auth-token-modal/:auth_token",
    name: "auth-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
