
export default {
    data: () => {
        return {
            success: null,
            email: '',
        }
    },
    methods: {
        async sendReset() {
            await this.$axios.$post(this.$settings.API.recoveryPasswordEmail, {email: this.email});
            this.success = true;
        }
    }
}
