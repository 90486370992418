
    export default {
        props: {
            noRedirect: Boolean
        },
        data: () => {
            return {
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                password: null,
                password_confirmation: null,
                opted_in: false,
                terms_and_conditions_agreement: false,

                success: null
            }
        },
        computed: {
            ready4reg() {
                return !!this.first_name && !!this.last_name && !!this.email && !!this.phone && !!this.password && !!this.password_confirmation && !!this.terms_and_conditions_agreement
            }
        },
        methods: {
            async register() {
                let {success, ...data} = this.$data;

                let redirect = this.$auth.options.redirect;
                if (this.noRedirect) this.$auth.options.redirect = false;
                try {
                    await this.$auth.loginWith('register', {data});
                    this.success = true;
                    await this.$bvModal.hide('modal-login');
                    await this.$nextTick();
                    setTimeout(() => {
                        this.$bvModal.msgBoxOk(
                            `You have successfully registered. Check your email to finish registration.`,
                            { centered: true, noFade: true }
                        );
                    }, 100);
                } catch(e) {
                    this.$bvToast.hide()

                    const errorMessages = Object
                        .values(e.response.data.errors)
                        .filter(val => Array.isArray(val))
                        .flat();

                    errorMessages.forEach(msg =>
                        this.$bvToast.toast(
                            msg,
                            {
                                title: 'Error',
                                variant:'danger',
                                toaster: 'b-toaster-top-center',
                                noAutoHide: true
                            }
                        ));
                } finally {
                    this.$auth.options.redirect = redirect;
                }
            }
        }
    }
