export default (context) => {
    let check_name_elements_first = true;
    function checkAttributes() {
        let rules = [
            'input:not([name])',
            'a[href="#"]:not([id]):not(.page-link)',
            'textarea:not([name])',
            'select:not([name])',
            'button:not([id]):not(:has([id]))',
            'th:not([id]).sortable',
            'th[aria-sort]:not([id])',
        ];

        const elements = $(rules.join(','))
            .filter(function() {
                return $(this).closest('.quillWrapper').length === 0;
            })
            .filter(function() {
                return $(this).closest('.gm-style').length === 0;
            })
        ;

        const style_str = 'outline: 3px solid red !important;';

        elements
            .filter(function() {
                return !($(this).attr('style')?.includes(style_str) ?? false)
            })
            .attr('style', function(i,s) { return (s || '') + style_str })
            .each(function() {
                let attribute = '';
                if ($(this).is('input, select')) {
                    attribute = 'name';
                } else if ($(this).is('a, button, th')) {
                    attribute = 'id';
                }
                if (check_name_elements_first) {
                    console.warn(`You should add the ${attribute} attribute for the element:`, this);
                }
            })
        ;
        check_name_elements_first = false;
    }
    if (context.isDev) {
        document.addEventListener("DOMContentLoaded", function() {
            setInterval(checkAttributes, 3000);
        });
    }
}



