
const MIN_WAIT_TIME_SECONDS = 30;

export default {
    props: {
        action: {
            type: String,
            default: ''
        },
        customer_id: {
            type: [String, Number],
            default: ''
        },
        onVerify: {
            type: Function,
            default: function(){}
        }
    },
    data() {
        return {
            code: '',
            timer: {
                ref: null,
                seconds: MIN_WAIT_TIME_SECONDS
            }
        }
    },
    watch: {
        value() {
            this.clear_errors();
        },
    },
    mounted() {
        this.getVerificationCode()
    },
    methods: {
        startTimer() {
            this.timer.seconds = MIN_WAIT_TIME_SECONDS;
            this.timer.ref = setInterval(() => {
                if (this.timer.seconds > 0) this.timer.seconds --;
                if (this.timer.seconds === 0) {
                    clearInterval(this.timer.ref)
                    this.timer.ref = null;
                }
            }, 1000);
        },
        async getVerificationCode() {
            try {
                await this.$axios.$get(this.$settings.API.actionVerificationCode, {
                    params: {
                        action: this.action,
                        customer_id: this.customer_id
                    }
                }, )
            } finally {
                this.startTimer()
            }
        },
        async submit() {
            const verification_hash = await this.$axios.$post(this.$settings.API.actionVerificationCode, {
                action: this.action,
                code: this.code
            });
            this.onVerify && this.onVerify(verification_hash)
            this.$emit('close')
        }
    }
}
