export default ({app, route, from, redirect, auth}) => {
    if (process.server) return;

    if (app?.$auth?.loggedIn
        && ![
            'user-profile',
            'property-property_id-property_slug-make-offer',
            'terms-of-service',
            'privacy-policy'
        ].includes(route.name)
        && app?.$auth.user?.phones?.find(r => r.pivot?.sms_default && !r.pivot?.is_confirmed)
    ) {
        redirect({name: 'user-profile'})
    }
}
