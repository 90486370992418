
    import Mixins from '~/plugins/app_mixins.js';

    export default {
	    mixins: [Mixins.socialAuth],
        props: {
            noRedirect: Boolean,
            width: {
                type: String,
                default: 'full'
            }
        },
        mounted() {
            window.addEventListener("message", this.listenerMessage);
        },
        destroyed() {
            window.removeEventListener("message", this.listenerMessage);
        },
        methods: {
            listenerMessage(event) {
	            if (event.origin !== this.getBackendOrigin() || !event.data) return;
                this.socialLogin(event.data);
            },
            async socialLogin(data) {
                let redirect = this.$auth.options.redirect;
                if (this.noRedirect) this.$auth.options.redirect = false;
                try {
                    await this.$auth.loginWith('social_login', {data});
                    this.$bvModal.hide('modal-login');
                } finally {
                    this.$auth.options.redirect = redirect;
                }
            }
        }
    }
