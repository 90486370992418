const middleware = {}

middleware['a_confirm_phone'] = require('../middleware/a_confirm_phone.js')
middleware['a_confirm_phone'] = middleware['a_confirm_phone'].default || middleware['a_confirm_phone']

middleware['account_slug'] = require('../middleware/account_slug.js')
middleware['account_slug'] = middleware['account_slug'].default || middleware['account_slug']

middleware['set_meta'] = require('../middleware/set_meta.js')
middleware['set_meta'] = middleware['set_meta'].default || middleware['set_meta']

export default middleware
