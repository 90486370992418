import Vue from 'vue';

import {
  ToastPlugin,
  ModalPlugin,
  CollapsePlugin,
  VBTooltipPlugin,
  IconsPlugin,
  BButton,
  BSpinner,
  BFormInput
} from 'bootstrap-vue';

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(CollapsePlugin);
Vue.use(VBTooltipPlugin);
Vue.use(IconsPlugin);

Vue.component('BButton', BButton);
Vue.component('BSpinner', BSpinner);
Vue.component('BFormInput', BFormInput);
