const EMPTY_LEAD = {
    id: null,
    property_id: null,
    price: null,
    down_payment: null,
    points: null,
    interest_rate: null,
    duration: null,
    credit_score: null,
    owned_properties_amount: null,
    sales_amount: null,
    borrowing_entity: '',
    exit_strategy: null,
    project_goals: '',
};

export const state = () => ({
    lead: {
        ...EMPTY_LEAD
    }
});

export const getters = {
    is_new_lead: (state) => !state.lead.id,
    lead: (state) => state.lead,
};

export const mutations = {
    update: (state, data) => {
        state.lead = {
            ...state.lead,
            ...data
        }
    },
    clear: state => {
        state.lead = {
            ...EMPTY_LEAD
        }
    }
}

export const actions = {
    async store({commit, state, getters}, draft) {
        const method = getters.is_new_lead ? this.$axios.$post : this.$axios.$put

        let data = await method(
            this.$settings.API.loanLead + (!getters.is_new_lead ? `/${state.lead.id}` : ''),
            {...state.lead},
            {params: {draft: draft ? 1 : 0}, suppressError: true}
        );

        commit('update', data)
    },
    async get({commit}, property_id) {
        let data = await this.$axios.$get(
            this.$settings.API.loanLead,
            {
                params: {property_id: property_id}
            }
        );
        if (data.id) {
            commit('update', data)
        } else {
            commit('clear')
        }
    }
}
