export default async function (ctx) {
    let {redirect, $auth, route, query: {auth_token}, store} = ctx;

    store.commit('SET', {favorite_properties: $auth.user?.favourite_properties ?? []});
    $auth.$storage.watchState('loggedIn', () => {
        store.commit('SET', {favorite_properties: $auth.user?.favourite_properties ?? []});
    })

    if (auth_token) {
        let redirect_param = $auth.options.redirect;
        $auth.options.redirect = false;
        try {
            await $auth.loginWith('token', {data: {auth_token}});
            delete route.query.auth_token;
            redirect(route);
            ctx.res = null;
        } catch (error) {
            redirect({name: 'login', query: {error_message: error}});
            ctx.res = null;
        } finally {
            $auth.options.redirect = redirect_param;
        }
    }
}
