export default ({route, from, redirect}) => {
    if (from?.name?.indexOf('company-account_slug-') === 0
        && route.name.indexOf('company-account_slug-') === -1
        && route.name.indexOf('direct-') === -1
        && !route?.params?.direct
        // TODO check that new route exists
    ) {
        route.name = 'company-account_slug-' + route.name;
        route.params.account_slug = from.params.account_slug;
        redirect(route);
    }
}
