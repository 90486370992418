
const
    URL_APP_STORE = 'https://apps.apple.com/do/app/investorlift/id6503916741',
    URL_PLAY_STORE = '',
    isAndroid = () => /Android/i.test(navigator.userAgent),
    isIos = () => /iPhone|iPad|iPod/i.test(navigator.userAgent),
    getAppUrl = () => {
        if(isAndroid()) return URL_PLAY_STORE;
        if(isIos()) return URL_APP_STORE;
        return null;
    }

export default {
    props: {
        rating: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            show: false,
            url: null
        }
    },
    computed: {
        cssProps() {
            return {
                '--rating': `${this.rating}`
            }
        }
    },
    mounted() {
        if (process.client) {
            const enabled = this.$config.mobile_overlay,
                dismissed = Boolean(sessionStorage.getItem('mobile-overlay-dismissed')),
                isMobile = isAndroid() || isIos();

            this.url = getAppUrl();
            this.show = enabled && isMobile && !dismissed && this.url;
        }
    },
    methods: {
        dismiss() {
            this.show = false;
            if (process.client) sessionStorage.setItem('mobile-overlay-dismissed', 'true');
        }
    },
    filters: {
        formatRating(v) {
            return v.toFixed(1);
        }
    }
}
