
    export default {
        data() {
	    	return {
	    		model: {
	    			address: {},
				    info: {},
                    details: {},
                    media: {},
                    agreement: false
                },
                login_mode: 'login',
	    		step: 0
            }
        },
        computed: {
            get_modal_size() {
                if (!this.$auth.loggedIn && this.login_mode !== 'register') return 'md';
                if (!this.$auth.loggedIn && this.login_mode === 'register') return 'lg';
                if ([0, 1, 3, 6].indexOf(this.step) != -1) return 'md';
                if ([2, 5].indexOf(this.step) != -1) return 'lg';
                if ([4].indexOf(this.step) != -1) return 'xl';
            },
	    	stepHeader() {
                if(!this.$auth.loggedIn) return 'Property Address';
	    		if(this.step === 0) return 'Property Address';
	    		if(this.step === 1) return 'Deal Info';
	    		if(this.step === 2) return 'Property Details';
	    		if(this.step === 3) return 'Property Images & Video';
	    		if(this.step === 4) return 'Deal Overview';
	    		if(this.step === 5) return 'Terms & Conditions';
	    		if(this.step === 6) return 'Deal Posted';
            }
        },
        methods: {
	    	nextStep() {
                let isValid = this.$refs[`step${this.step}`].validate?.() || true;
                if(typeof isValid === 'object') {
                    isValid.then(() => {
                        this.step ++;
                    }).catch(error => {
                    });
                } else {
                    if(isValid) this.step ++;
                }
            },
	        prevStep() {
                let saveProgress = this.$refs[`step${this.step}`].saveData?.();
                if(typeof saveProgress === 'object') {
                    saveProgress.then(() => {
                        this.step --;
                    })
                } else {
                    this.step --;
                }
            },
	        async postDeal() {
                if(!(this.$refs[`step${this.step}`].validate?.() ?? true)) {
                    return false;
                }

	    		let {
	    			state,
                    ...data
	    		} = {
				    ...this.model.address,
				    ...this.model.info,
				    ...this.model.details,
				    video: this.model.media.video && this.model.media.video.code ? this.model.media.video.code : ''
			    };

		        let formData = new FormData();
                this.model.media.images.forEach((image, key) => {
			        formData.append(`file${key}`, image);
		        });

		        Object.keys(data).forEach(key => {
			        formData.append(key, data[key] || '');
                });

		        await this.$axios.$post(this.$settings.API.properties, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if(this.$route.name !== 'user-deals') {
                    this.$router.push({name: 'user-deals'});
                } else {
                    this.$nuxt.$emit('deal:added');
                }
                this.step ++;
            },
            setStep() {
                if(this.step === 6) this.step = 0;
            }
        }
    }
