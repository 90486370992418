
	import { library } from '@fortawesome/fontawesome-svg-core'
	import { faBuilding, faCalendarAlt, faBed, faBath, faParking, faThLarge, faChartArea } from '@fortawesome/free-solid-svg-icons'
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import GoogleRichMarker from "~/assets/utils/google-richmarker";

	library.add([faBuilding, faCalendarAlt, faBed, faBath, faParking, faThLarge, faChartArea]);

    export default {
    	components: {
		    FontAwesomeIcon
        },
    	props: {
    		deal: {
    			type: Object,
                default: () => {
                	return {}
                }
            }
        },
        data() {
    		return {
			    index: 0,
			    galleryIndex: null,
			    showStreetView: null,
			    propertyTypes: [],
			    parkingTypes: [],
                map: null
            }
        },
	    computed: {
		    preparedImages() {
			    return this.deal.media.images.map(item => {
			    	return {
			    		url: URL.createObjectURL(item)
                    }
                })
		    }
	    },
        fetch() {
            this.loadPropertyTypes();
            this.loadParkingTypes();
        },
        mounted() {
    		this.initMap();
        },
	    methods: {
            async loadPropertyTypes() {
                this.propertyTypes = await this.$axios.$get(this.$settings.API.propertyType);
            },
            async loadParkingTypes() {
                this.parkingTypes = await this.$axios.$get(this.$settings.API.parkingType);
            },
		    getPropertyType(id) {
		    	return (this.propertyTypes.find(item => item.id === id) || {}).title
            },
		    getParkingType(id) {
		    	return (this.parkingTypes.find(item => item.id === id) || {}).title
            },
	        next() {
		        if(this.index < this.preparedImages.length - 1) {
		        	this.index ++;
		        }
	        },
	        prev() {
		        if(this.index > 0) this.index --;
	        },
	        initMap() {
		        if (!window.google) {
			        this.$nuxt.$once('googleMapApi:init', this.initMap);
			        return;
		        }

		        this.map = new google.maps.Map(this.$refs['propertyMap'], {
			        zoom: this.$settings.propertyMapZoom,
			        center: {
				        lat: Number(this.deal.address.latitude),
				        lng: Number(this.deal.address.longitude)
			        },
			        styles: [{
				        "featureType": "water",
				        "elementType": "all",
				        "stylers": [
					        {
						        "color": '#a4ddf5'
					        },
					        {
						        "visibility": "on"
					        }
				        ]
			        }],
			        zoomControl: true,
			        zoomControlOptions: {
				        position: google.maps.ControlPosition.LEFT_BOTTOM
			        },
			        streetViewControl: true,
			        streetViewControlOptions: {
				        position: google.maps.ControlPosition.LEFT_BOTTOM
			        }
		        });

		        new GoogleRichMarker.RichMarker({
			        position: new google.maps.LatLng(this.deal.address.latitude, this.deal.address.longitude),
			        map: this.map,
			        draggable: false,
			        shadow: 'none',
			        content: this.$refs['property-marker'].outerHTML
		        });

		        // street view
		        let whereToLookLatLng = new google.maps.LatLng(this.deal.address.latitude, this.deal.address.longitude);
		        let panorama = new google.maps.StreetViewPanorama(
			        this.$refs['property-street-view'],
			        {
			        	position: whereToLookLatLng,
                        addressControl: false,
                        showRoadLabels: false
			        }
		        );
		        let service = new google.maps.StreetViewService;
		        service.getPanoramaByLocation(panorama.getPosition(), 50, (panoData, status) => {
			        if (status !== google.maps.StreetViewStatus.OK) {
				        return;
			        }
			        this.showStreetView = true;
			        let ManLatLng = panoData.location.latLng;
			        let heading = google.maps.geometry.spherical.computeHeading(ManLatLng, whereToLookLatLng);
			        let pov = panorama.getPov();
			        pov.heading = heading;
			        panorama.setPov(pov);
			        panorama.setZoom(2);
		        });
	        },
        }
    }
