export default ({route, store}) => {
    // Take the last value (latest route child)
    let title = route.meta.reduce((title, meta) => meta.title || title, undefined);
    let breadcrumbs = [{path: '/', title: 'Home'}];
    let calc_meta = {
        header: true,
        titlebar: true,
        footer: true,
    };

    route.meta.forEach((meta, index) => {
        Object.assign(calc_meta, meta);
        if (!meta.title) return;
        breadcrumbs.push({path: route.matched[index].path, title: meta.title});
    });
    breadcrumbs[breadcrumbs.length - 1].path = null;

    store.commit('set_meta', {...calc_meta, title, breadcrumbs});
}
