
    import { TheMask } from 'vue-the-mask'

    export default {
        components: {
            TheMask
        },
        props: ['type', 'value', 'name', 'fieldClass', 'notexterror'],
        watch: {
            value(newVal, oldVal) {
                this.localValueFormatted = newVal;
            }
        },
        computed: {
            localValueFormatted: {
                get: function () {
                    if (this.type === 'phone') {
                        return this.value
                            ? String(this.value ?? '').replace('+1', '')
                            : '';
                    } else {
                        return this.value ?? ''
                    }
                },
                set: function (newValue) {
                    let val;
                    if (this.localValueFormatted !== newValue) {
                        if (this.type === 'phone') {
                            val = (newValue && (newValue !== '('))
                                ? ('+1' + String(newValue ?? '')
                                    .replace(/^\+1/gi, '')
                                    .replace(/[^0-9]+/gi, '')
                                )
                                : '';
                        } else {
                            val = newValue ?? ''
                        }
                        if (this.type === 'price') {
                            this.$emit('input', Number(val))
                        } else {
                            this.$emit('input', val)
                        }
                    }
                }
            },
            className() {
                let classes = [];
                if (this.fieldClass) {
                    classes.push(this.fieldClass);
                }
                return classes;
            }
        },
        methods: {
            async onFocus() {
                this.$refs.field_the_mask?.refresh?.(this.localValueFormatted);
                this.$emit('focus', this.name);
            },
        }
    }
