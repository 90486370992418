export default (context, inject) => {
    let helper = {};

    let hex2hsl = (hex) => {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        let r = parseInt(result[1], 16);
        let g = parseInt(result[2], 16);
        let b = parseInt(result[3], 16);

        r /= 255, g /= 255, b /= 255;
        let max = Math.max(r, g, b);
        let min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        s = s*100;
        s = Math.round(s);
        l = l*100;
        l = Math.round(l);
        h = Math.round(360*h);

        return [h, s, l];
    };

    let color_lighten = (color, lighten) => {
        let [h, s, l] = hex2hsl(color);
        return 'hsl(' + h + ', ' + s + '%, ' + (l + lighten) + '%)';
    };

    helper.color_lighten = color_lighten;

    helper.canUseWebp = (function() {
        if (process.server) return true;
        const elem = document.createElement("canvas");
        if (!!(elem.getContext && elem.getContext("2d"))) {
            return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
        } else {
            return false;
        }
    })();

    helper.getImageUrl = url => {
        if (!url) return '';
        // Do not change urls for thumbnails
        if (url.indexOf('-th1.') !== -1) return url;

        let url_arr = url.split('.');
        let extention = url_arr.pop();

        if (extention === 'webp' && process.client && !helper.canUseWebp) {
            url_arr.push("jpg");
            return url_arr.join('.');
        } else {
            return url;
        }
    };

    helper.getCssBackgroundImage = url => {
        if (!url) return;
        return {backgroundImage: `url(${helper.getImageUrl(url)})`};
    };

    inject('helper', helper);
}
