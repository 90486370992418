import AxiosError from "~/utils/AxiosError";

export default async function (ctx) {
    let { redirect, error, route, store, app, clearError } = ctx;

    if (route.query?.error_message) {
        store.commit('axios/set_error_message', route.query.error_message);
    }

    let handleError = (error) => {
        // if error is undefined but the error handler still called
        if (!error) return false

        let { message, status, errors } = error

        if (!message) {
            if (errors) {
                // Handle form errors
                // Added by Dmitry Shirokov on Jan 28, 2023 - do not show a toast for validation errors
                // message = Array.isArray(Object.values(errors)[0]) ? Object.values(errors)[0][0] : Object.values(errors)[0]
            } else {
                // Case if error with no message
                message = 'Unknown error'
            }
        }

        if (error instanceof AxiosError) {
            const err_message = message ?? errors?.message;
            const err_link = error?.errors?.err_link;
            const support_info = error?.errors?.support_info;

            let final_message = err_message;
            if (err_link) {
                final_message += `\n (${err_link})`;
            }
            if (support_info) {
                final_message += `\n ${support_info}`;
            }

            store.commit('axios/set_error_message', final_message);
            return true;
        } else {
            return false;
        }

        // in case we'll like a custom toast for some special case
        // toast('An error has occurred')
    }

    // in case some method is called in-non async function it results in global unhandledrejection event
    if (process.client) {
        window?.addEventListener("unhandledrejection", function (promiseRejectionEvent) {
            handleError(promiseRejectionEvent.reason)
        });

        const h = app.nuxt.error
        app.nuxt.error = (err) => {
            const showErrorPage = handleError(err)
            if (store.state.axios.dont_show_nuxt_error_page) {
                ctx.error = undefined
                return false;
            }
            if (!showErrorPage) {
                h(err)
                ctx.error = undefined
            }
            return true;
        };

    }


}
