
export default {
    props: {
        value: Boolean,
        tabindex: Number
    },
    data: () => {
        return {
            checked: false
        }
    },
    watch: {
        checked(newVal) {
            this.$emit('changed', newVal);
        }
    },
    mounted() {
        this.checked = this.value;
    },
}
