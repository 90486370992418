import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex';
import hmacSHA256 from 'crypto-js/hmac-sha256'
import AxiosError from "~/utils/AxiosError";
import { resolveTimezone } from "~/utils/timezone";

if (!Vue.__axios_loading_errors__) {
    Vue.__axios_loading_errors__ = true;

    Vue.mixin({
        watch: {
            '$fetchState.error'(error) {
                this.$store.commit('axios/set_errors', error?.errors);
                this.$store.commit('axios/set_error_message', error?.message);
            }
        },
        computed: {
            ...mapGetters({
                axios_loading: 'axios/loading',
                errors: 'axios/errors',
                error_message: 'axios/error_message',
            }),
        },
        methods: {
            ...mapMutations({
                clear_errors: 'axios/clear_errors',
                set_errors: 'axios/set_errors'
            })
        }
    });
}

export default function ( { app, $config, $axios, store, req, error: nuxtError } ) {

    app.router.beforeEach((to, from, next) => {
        // name null when reload page F5
        if (from.name) {
            store.commit('axios/clear_errors');
            store.commit('axios/clear_error_message');
        }
        next();
    });

    $axios.setBaseURL($config.api_url);

    const timezone = resolveTimezone();
    if (timezone) $axios.defaults.headers.common['X-Client-Timezone'] = timezone;

    $axios.onRequest(config => {
        if (process.client) {
            store.commit('axios/increment_connect_count', config?.loader);
        }
        store.commit('axios/clear_errors');
        store.commit('axios/clear_error_message');

        if (process.server) {
            config.headers['Referer'] = 'https://' + (req.headers?.originalhost || req.headers?.host);
        }

        if (!config.external) {
            config.headers['BS-Signature'] = hmacSHA256(
                JSON.stringify(config.data),
                $config.cur_site_config.domain_name.api_key
            ).toString();
        }
        return config;
    });

    $axios.onError(error => {
        if (process.client) {
            store.commit('axios/decrement_connect_count', error?.config?.loader);
        }
        if (error?.response?.status === 500) {
            console.error(error?.config?.url, error?.response?.data);
        }
        if (error?.response?.status === 403 && error?.response?.data?.action === 'is_banned') {
            app.$auth.logout();
        }

        let errorMessage
        let laravelFieldsErrors = error?.response?.data?.errors;

        if (!error?.config?.suppressError) {
            errorMessage = error?.response?.data?.message
                || error?.response?.data?.messages?.join('\n')
                || error?.response?.data?.error
                || error.toString()
            ;
            if (error?.response?.data?.errors?.length) {
                errorMessage = error?.response?.data?.errors?.join('\n')
                laravelFieldsErrors = null;
            }

        }

        const status = error?.response?.status
        const message = errorMessage

        throw new AxiosError(message, status, laravelFieldsErrors, error)
    });


    $axios.onResponse(({config}) => {
        if (process.client) {
            store.commit('axios/decrement_connect_count', config?.loader);
        }
    });

}
