import { render, staticRenderFns } from "./login.vue?vue&type=template&id=45d1dac7&scoped=true"
import script from "./login.vue?vue&type=script&lang=js"
export * from "./login.vue?vue&type=script&lang=js"
import style0 from "./login.vue?vue&type=style&index=0&id=45d1dac7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d1dac7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthSocial: require('/app/components/auth/social.vue').default,AuthRegisterForm: require('/app/components/auth/register-form.vue').default,AuthForgotPassword: require('/app/components/auth/forgot-password.vue').default})
