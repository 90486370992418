export default {
    socialAuth: {
        methods: {
            getBackendOrigin() {
                if(this.backendOrigin) return this.backendOrigin;
                let url = new URL(this.$config.social_login_url || this.$config.api_url);

                this.backendOrigin = url.protocol + '//' +  url.host;
                return this.backendOrigin;
            },
            getSocialAuthIframeSrc(network, action) {
                return `${this.getBackendOrigin()}${this.$settings.socialAuthIframeBaseUrl}/${network}/${action}`;
            },
        }
    }
}
