
    export default {
        data() {
            return {
                app_loading: false
            }
        },
        methods: {
            start() {
                this.app_loading = true
            },
            finish() {
                this.app_loading = false
            }
        }
    }
