
    export default {
        props: {
    		value: {
    			type: Object,
                default: () => {
                	return {}
                }
            }
        },
    	data() {
    		return {
    			model: {
				    title: '',
    				arv_estimate: 0,
                    price: 0,
                    buy_now_price: 0,
                    min_emd: 0,
                    ...this.value
                },
			    money: {
				    decimal: ',',
				    thousands: ',',
				    prefix: '$ ',
				    suffix: '',
				    precision: 0,
				    masked: false
			    },
            }
        },
        methods: {
	        validate() {
		        return new Promise((resolve, reject) => {
			        this.validateData().then(result => {
				        this.$emit('input', {...this.model});
				        resolve()
			        }).catch(error => {
				        reject();
			        })
		        })
	        },

	        validateData() {
                /**
                 * Fix types for correct validation
                 */
	        	let data = {...this.model};
	        	if (data.buy_now_price == 0) {
	        	    data.buy_now_price = null;
                } else {
                    data.buy_now_price = Number(data.buy_now_price);
                }
                data.price = Number(data.price);

		        return this.$axios.$post(this.$settings.API.prePostDealPrice, data);
	        },

            saveData() {
	            this.$emit('input', {...this.model});
            }
        }
    }
