export const state = () => ({
    connect_count: {
        global: 0
    },
    errors: {},
    error_message: '',
    dont_show_nuxt_error_page: false
});

export const getters = {
    loading: (state) => state.connect_count,
    errors: (state) => state.errors,
    error_message: (state) => state.error_message,
    dont_show_nuxt_error_page: (state) => state.dont_show_nuxt_error_page
};

export const mutations = {
    increment_connect_count: (state, loader = 'global') => {
        state.dont_show_nuxt_error_page = false;
        state.connect_count[loader] = (state.connect_count[loader] ?? 0) + 1;
        state.connect_count = {...state.connect_count};
    },
    decrement_connect_count: (state, loader = 'global') => {
        state.dont_show_nuxt_error_page = false;
        state.connect_count[loader] = (state.connect_count[loader] ?? 0) - 1;
        state.connect_count = {...state.connect_count};
    },
    clear_errors: (state, field) => {
        if (field) {
            if (state.errors?.[field]) state.errors[field] = null;
        } else {
            state.errors = {};
        }
    },
    clear_error_message: state => {
        state.error_message = '';
    },
    set_errors: (state, errors) => {
        state.dont_show_nuxt_error_page = true;
        state.errors = errors || {};
    },
    set_error: (state, error) => {
        state.errors = {...state.errors, ...error};
    },
    set_error_message: (state, error_message) => {
        state.error_message = error_message || '';
    }
};
