
    export default {
    	props: {
    		value: {
    			type: Boolean,
                default: false
            }
        },
    	data() {
    		return {
    			model: {
    				agreement: this.value
                }
            }
        },
        watch: {
    		'model.agreement'(newVal) {
			    this.clear_error({agreement: null});
            }
        },
        methods: {
    		validate() {
    			if(!this.model.agreement) {
                    this.$store.commit('axios/set_error', {agreement: ['You need to agree with the Terms and Conditions to proceed']});
                    return false;
                } else {
    				this.saveData();
    				return true;
                }
            },
            saveData() {
	            this.$emit('input', this.model.agreement);
            }
        }
    }
