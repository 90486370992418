

import moment from "moment";

export default {
    props: ['p_phone'],
    data() {
        return {
            show_modal: false,
            sms_timer_sec: null,
            status: null,
            message: null,
            sms_code: null,
            timer: null,
        }
    },
    computed: {
        moment() {
            return moment
        },
        mandatory_phone() {
            return this.$auth.user.phones.find(r => r.pivot.sms_default && !r.pivot.is_confirmed);
        },
        need_phone() {
            return !this.p_phone
                && this.$auth.loggedIn
                && ![
                    'user-profile',
                    'property-property_id-property_slug-make-offer',
                    'terms-of-service',
                    'privacy-policy'
                ].includes(this.$route.name)
                && this.mandatory_phone
            ;
        },
        phone() {
            return this.p_phone ?? this.need_phone;
        },
    },
    watch: {
        phone(new_val) {
            this.show_modal = !!new_val;
        },
        async sms_code(new_val) {
            if (/^[0-9]{6}$/.test(new_val)) {
                await this.confirmPhoneBySmsCode(null);
            }
        },
    },
    mounted() {
        this.show_modal = !!this.phone;
    },
    methods: {
        async getConfirmationSmsCode() {
            let res = await this.$axios.$post(
                this.$settings.API.getConfirmationSmsCode,
                {entity_phone_id: this.phone.pivot.id},
                {loader: 'getConfirmationSmsCode'}
            );
            this.sms_timer_sec = res.sms_timer_sec;
            this.status = res.status;
            this.message = res.message;

            this.setSendTimeout();
        },
        setSendTimeout() {
            if (this.sms_timer_sec) {
                clearInterval(this.timer);
                setInterval(() => {
                    this.sms_timer_sec--;
                    if (this.sms_timer_sec <= 0) {
                        clearInterval(this.timer);
                        this.sms_timer_sec = null;
                        this.message = null;
                    }
                }, 1000);
            }
        },
        async confirmPhoneBySmsCode(event) {

            let res = await this.$axios.$post(this.$settings.API.confirmPhoneBySmsCode,
                {entity_phone_id: this.phone.pivot.id, sms_code: this.sms_code},
                {loader: 'confirmPhoneBySmsCode'}
            );

            this.status = res.status;
            this.message = res.message;

            if (this.status === 'approved') {
                await this.$auth.fetchUser();
            }
        },
        validate(event) {
            this.need_phone ? event.preventDefault() : (this.$emit('close') && (this.show_modal = false))
        },
    }
}
