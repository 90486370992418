
    export default {
	    props: {
		    value: {
			    type: Object,
			    default: () => {
				    return {}
			    }
		    }
	    },
    	data() {
    		return {
    			model: {
    				description: null,
                    bedrooms: null,
                    bathrooms: null,
                    year_built: null,
                    sq_footage: null,
                    lot_size: null,
				    property_type_id: null,
                    parking_type_id: null,
                    ...this.value
                },
                propertyTypes: [],
                parkingTypes: []
            }
        },
        fetch() {
	    	this.loadPropertyTypes();
	    	this.loadParkingTypes();
        },
	    methods: {
		    async loadPropertyTypes() {
                this.propertyTypes = await this.$axios.$get(this.$settings.API.propertyType);
                if(this.propertyTypes.length && !this.model.property_type_id) {
                    this.$nextTick(() => {
                        this.model.property_type_id = this.propertyTypes[0].id;
                    })
                }
            },

		    async loadParkingTypes() {
                this.parkingTypes = await this.$axios.$get(this.$settings.API.parkingType);
                if(this.parkingTypes.length && !this.model.parking_type_id) {
                    this.$nextTick(() => {
                        this.model.parking_type_id = this.parkingTypes[0].id;
                    })
                }
            },

		    validate() {
			    return new Promise((resolve, reject) => {
				    this.validateData().then(result => {
					    this.$emit('input', {...this.model});
					    resolve()
				    }).catch(error => {
					    reject();
				    })
			    })
		    },

		    saveData() {
			    this.$emit('input', {...this.model});
		    },

		    validateData() {
                return this.$axios.$post(this.$settings.API.prePostDealDetails, this.model);
		    },
	    }
    }
