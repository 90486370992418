

    const geotag_types = {
        'country': 'Country',
        'administrative_area_level_1': 'State',
        'administrative_area_level_2': 'County',
        'administrative_area_level_3': 'City',
        'locality': 'City',
        'sublocality': 'City',
        'neighborhood': 'City',
        'colloquial_area': 'City',
    };

    export default {
        data() {
            return {
                geotags: [],
                geotag: null,
                geotag_type: null,
                openDialogue: false,
            }
        },
        watch: {
            geotags() {
                this.setUserGeotagInfo();
            },
        },
        async fetch() {
            this.geotags = (await this.$axios.$get(this.$settings.API.userGeotags)).geotags;
        },
        mounted() {
            this.initAutocomplete();
        },
        methods: {
            openPopup() {
                this.openDialogue = true;
            },
            clearGeotag() {
                this.geotag_type = null;
                this.geotag = null;
            },
            initAutocomplete() {
                if (!window.google) {
                    this.$nuxt.$once('googleMapApi:init', this.initAutocomplete);
                    return;
                }
                if (!this.$refs['pac-input']) {
                    return;
                }

                let searchBox = new google.maps.places.Autocomplete(this.$refs['pac-input'], {
                    types: ['(regions)'],
                    componentRestrictions: {country: ["us", "pr"]}
                });
                searchBox.addListener('place_changed', () => {
                    let place = searchBox.getPlace();

                    this.clearGeotag();

                    if (!place || !place.address_components || !place.address_components.length) {
                        this.$refs['pac-input'].value = '';
                        return;
                    }

                    this.geotag_type = geotag_types[Object.keys(geotag_types).filter(v => place.types.includes(v))[0]];

                    if (!this.geotag_type) {
                        this.$refs['pac-input'].value = '';
                        return;
                    }

                    let {
                        photos,
                        adr_address,
                        icon,
                        icon_background_color,
                        icon_mask_base_uri,
                        ...clean_place
                    } = place;

                    this.geotag = {status: 'OK', results: [{...clean_place}]};
                });
            },
            async submit() {
                let responseData = await this.$axios.$post(this.$settings.API.userGeotags, {
                    geotag: this.geotag
                });

                this.geotags = responseData.geotags;
                this.clearGeotag();
                this.$refs['pac-input'].value = '';

            },
            async deleteGeotag(geotag_id) {
                this.geotags = (await this.$axios.$delete(this.$settings.API.userGeotags + '/' + geotag_id)).geotags;
            },
            setUserGeotagInfo() {
                this.$auth.$storage.setUniversal("user", {
                    ...this.$auth.user,
                    geotags: this.geotags.map(r => r.id)
                });
            },
            async removeAllItems() {
                this.geotags = (await this.$axios.$delete(this.$settings.API.customerGeotagsAllDelete)).geotags;
                this.openDialogue = false;
                this.$bvToast.toast('Your locations have been successfully removed.', {
                    title: 'Success',
                    variant: 'success',
                    solid: false,
                    toaster: 'b-toaster-top-center'
                })
            }
        }
    }
