

export default {
    name: "default",
    head() {
        return {
            title: this.$store.state.meta.title,
            titleTemplate: (title) => {
                let res = [];
                if (title) res.push(title);
                res.push(this.$config.cur_site_config.company_name);
                return res.join(' - ');
            }
        }
    },
    data() {
        return {
            customCss: this.$config.cur_site_config.custom_css,
            login_mode: 'login',
            verify_action_options: {
                action: null,
                customer_id: null,
                onVerify: function(){}
            }
        }
    },
    computed: {
        get_modal_size() {
            return this.login_mode === 'register' ? 'lg' : 'md';
        },
    },
    mounted() {
        this.$nuxt.$on('open_modal_login', this.openModalLogin);
        this.$nuxt.$on('open_modal_verify_action', this.openModalVerifyAction);

        window.addEventListener("load", event => {

            let timer;

            let func = () => {
                if (!this.$config?.cur_site_config) return;

                clearInterval(timer);

                // Implement GTM
                if (this.$config.cur_site_config.google_tag_manager_key) {
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.defer=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', this.$config.cur_site_config.google_tag_manager_key);

                    document.body.insertAdjacentHTML(
                        'afterbegin',
                        `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${this.$config.cur_site_config.google_tag_manager_key}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
                    );
                }
            }

            timer = setInterval(func, 100);

        });
    },
    destroyed() {
        this.$nuxt.$off('open_modal_login', this.openModalLogin);
        this.$nuxt.$off('open_modal_verify_action', this.openModalVerifyAction);
    },
    methods: {
        loginClosed() {
            this.$nuxt.$emit('login:closed');
        },
        openModalLogin(mode) {
            this.login_mode = mode;
            this.$bvModal.show('modal-login');
        },
        openModalVerifyAction(options) {
            options = options || {}
            this.verify_action_options = {...options};
            this.$bvModal.show('modal-verify-action');
        }
    },
    errorCaptured (error) {
        // We need this to capture Axios errors in prod mode
        console.log('Error: ', error)
        this.$root.nuxt.error(error);
    }
}
