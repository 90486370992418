
    export default {
        data: () => {
            return {
                phone: null,
                agree_checked: true,
                modal_state: false,
                geo_need_long: false,
                opted_in: false,
            }
        },
        computed: {
            phone_needed() {
                return !this.$auth.user?.phones?.length ?? false;
            },
            geotags_needed() {
                let need = this.$auth.loggedIn && !this.$auth.user?.geotags?.length;
                if (need) this.geo_need_long = true;
                return need || this.geo_need_long;
            },
            need_details() {
                return this.$auth.loggedIn
                    && (
                        (this.phone_needed && this.$route.name !== 'user-profile')
                        || (this.geotags_needed && this.$route.name !== 'user-notification-settings')
                        || this.accept_terms_needed
                    )
                    && this.$route.name !== 'property-property_id-property_slug-make-offer'
                    && this.$route.name !== 'terms-of-service'
                    && this.$route.name !== 'privacy-policy'
                ;
            },
            accept_terms_needed() {
                return !this.$auth.user?.terms_and_conditions_agreement_at ?? false;
            },
            disable_continue() {
                return (!this.agree_checked && this.accept_terms_needed)
                    || (!this.phone && this.phone_needed)
                    || !this.$auth.user?.geotags?.length
            }
        },
        watch: {
            need_details(newVal) {
                if (newVal) {
                    this.$bvModal.show('request-details');
                } else {
                    if(this.modal_state) this.$bvModal.hide('request-details');
                }
            },
        },
        async fetch() {
            if (this.need_details) {
                await this.$nextTick();
                this.$bvModal.show('request-details');
            }
        },
        methods: {
            setRequired() {
                setTimeout(() => {
                    if (this.phone_needed) {
                        this.$store.commit('axios/set_error', {phone: ['Phone required']});
                    }
                }, 1000);
            },
            async savePhone() {
                let phones = await this.$axios.$post(this.$settings.API.phone, {
                    phone: this.phone,
                    opted_in: this.opted_in
                });
                this.$auth.$storage.setUniversal("user", {...this.$auth.user, phones});
                if (phones) return true;
            },
            async acceptTerms() {
                let date = await this.$axios.$post(this.$settings.API.userAcceptTerms);
                this.$auth.$storage.setUniversal("user", {...this.$auth.user, terms_and_conditions_agreement_at: date });
                if (date) return true;
            },
            validate(event) {
                if (this.need_details) event.preventDefault();
            },
            async logoutAndClose() {
                await this.$auth.logout();
                this.$bvModal.hide('request-details')
            },
            saveAndContinue() {
                if (this.agree_checked && this.accept_terms_needed)
                {
                    if (!this.acceptTerms()) return;
                }
                if (this.phone && this.phone_needed) {
                    if (!this.savePhone()) return;
                }

                if (this.geo_need_long) this.geo_need_long = false;
            },
        }
    }
