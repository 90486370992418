
    export default {
        inheritAttrs: false,
        props: ['to'],
        computed: {
            createLink() {
                if (!this.$route?.params?.account_slug || !this.to || this.to?.params?.direct) {
                    return this.to;
                }
                if (typeof this.to === 'string') {
                    if (this.to.indexOf('/company/') >= 0) return this.to;
                    return '/company/' + this.$route.params.account_slug + this.to;
                } else {
                    if (this.to.name.indexOf('company-account_slug-') >= 0) return this.to;
                    this.to.name = 'company-account_slug-' + this.to.name;
                    this.to.params = this.to.params ?? {};
                    this.to.params.account_slug = this.$route.params.account_slug;
                    return this.to;
                }
            }
        }
    }
