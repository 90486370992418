import Vue from 'vue'

Vue.filter('shortprice', (value) => {
    if (!Array.isArray(value)) value = [value];
    return value.map(value => {
        let settings = {
            divider: 1000,
            toFix: 0,
            metricPrefix: 'k',
            divider2: 1,
        };
        if (Number(value) >= 1000000) {
            settings.divider = 10000;
            settings.toFix = 2;
            settings.metricPrefix = 'M';
            settings.divider2 = 100;
        }
        let n = Math.round(Number(value) / settings.divider);
        return '$' + (n / settings.divider2).toFixed(settings.toFix)
            .replace(/./g, (c, i, a) => {
                return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
            }) + settings.metricPrefix;
    }).join(' - ');
});

Vue.filter('fullprice', (value) => {
    let n = Number(value);
    if (isNaN(n)) {
        return '-';
    }
    return '$' + n.toFixed(0).replace(/./g, (c, i, a) => {
        return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
    });
});

Vue.filter('phoneNumberLocal', value => {
    const cleaned = ('' + value)
        .replace('+1', '')
        .replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return value;
});

Vue.filter('localeString', value => {
    return value?.toLocaleString()
})
